'use client'

import type { PropsWithChildren } from 'react'
import { createContext, useContext, useMemo, useState } from 'react'

export interface FieldInputCache {
  [key: string]: string | number | string[]
}

export interface ConfigState {
  view: ViewVariants
}

export type ViewVariants = 'Overview' | 'Code' | 'Result' | 'Snippets'

export interface PlaygroundContextState {
  data: FieldInputCache
  config: ConfigState
  initiator: string | null
}

interface PlaygroundContextActions {
  updateFieldCache: (key: string, value: string | number | string[]) => void
  switchView: (view: ViewVariants) => void
  setInitiator: (initiator?: string) => void
}

export const PlaygroundContext = createContext<
  [PlaygroundContextState, PlaygroundContextActions]
>([
  { data: {}, config: { view: 'Overview' }, initiator: null },
  {
    updateFieldCache: () => undefined,
    switchView: () => undefined,
    setInitiator: () => undefined,
  },
])

export const PlaygroundProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [cache, setCache] = useState<FieldInputCache>({
    timer: new Date().toString(),
  })
  const [config, setConfig] = useState<ConfigState>({
    view: 'Overview',
  })
  const [initiator, setInitiator] = useState<string | null>(null)

  const state: PlaygroundContextState = useMemo(
    () => ({ data: cache, config, initiator }),
    [cache, config, initiator],
  )

  const actions: PlaygroundContextActions = useMemo(
    () => ({
      updateFieldCache(key, value) {
        setCache((prev) => ({
          ...prev,
          [key]: value,
        }))
      },
      switchView(view: ViewVariants) {
        setConfig((prev) => ({ ...prev, view }))
      },
      setInitiator(initiator?: string) {
        setInitiator(initiator ?? null)
      },
    }),
    [],
  )

  return (
    <PlaygroundContext.Provider value={[state, actions]}>
      {children}
    </PlaygroundContext.Provider>
  )
}

export const usePlaygroundContext = () => {
  return useContext(PlaygroundContext)
}
