'use client'

import { createContext, useContext } from 'react'

import type { NavigationContextState } from '@features/navigation.containers'

export const NavigationContext = createContext<NavigationContextState>([
  false,
  () => undefined,
])

export const useNavigation = () => {
  const context = useContext(NavigationContext)

  if (context === undefined) {
    throw new Error('useNavigation must be used within a NavigationProvider')
  }

  return context
}
