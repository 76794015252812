import type { MutableRefObject } from 'react'
import { createContext, useContext } from 'react'

import type {
  GlobalPlaygroundEventEmitterType,
  GlobalProjectSettings,
  GlobalSettings,
} from '@features/playground.constants/events'
import type Emittery from 'emittery'

/**
 * Represents the state of the Global Playground context.
 * This interface defines the structure of the state that is shared across the Global Playground components.
 */
export interface GlobalPlaygroundState {
  /**
   * A mutable reference to the current global settings.
   */
  global: MutableRefObject<GlobalSettings>

  /**
   * A mutable reference to the current project settings.
   */
  project: MutableRefObject<GlobalProjectSettings>
}

/**
 * Represents the actions available in the Global Playground context.
 * This interface defines the methods that can be used to interact with the Global Playground state.
 */
export interface GlobalPlaygroundActions {
  /**
   * The event emitter for global playground events.
   * This emitter is used to broadcast project setting changes or code snippet configuration changes.
   */
  emitter: Emittery<GlobalPlaygroundEventEmitterType>

  /**
   * Handles changes to global settings.
   * @param settings - The new partial global settings.
   */
  handleGlobalSettingsChange: (settings: Partial<GlobalSettings>) => void

  /**
   * Handles changes to project settings.
   * @param key - The key of the project setting to change.
   * @param settings - The new settings for the specified key.
   * @param initiator - Identifier of the component that triggered the change.
   */
  handleProjectSettingsChange: (
    key: string,
    settings: Record<string, string | number>,
    initiator?: string,
  ) => void
}

/**
 * The type of the Global Playground context, combining state and actions.
 */
export type GlobalPlaygroundContextType = [
  GlobalPlaygroundState,
  GlobalPlaygroundActions,
]

/**
 * Default values for the Global Playground context.
 * This is used to provide a type-safe default value for the context.
 */
export const defaultContextValue: GlobalPlaygroundContextType = [
  {
    global: {
      current: {
        language: 'node',
        client: 'fetch',
      },
    },
    project: { current: {} },
  },
  {
    emitter: {} as Emittery<GlobalPlaygroundEventEmitterType>,
    handleGlobalSettingsChange: () => {},
    handleProjectSettingsChange: () => {},
  },
]

/**
 * Context for the Global Playground.
 * This context provides the state and actions for the Global Playground to all child components.
 */
export const GlobalPlaygroundContext =
  createContext<GlobalPlaygroundContextType>(defaultContextValue)

/**
 * Hook to access the Global Playground context.
 * This hook provides a convenient way for components to consume the Global Playground context.
 * @returns A tuple containing the Global Playground state and actions.
 * @throws If used outside of a GlobalPlaygroundProvider.
 */
export const useGlobalPlaygroundContext = (): GlobalPlaygroundContextType => {
  const context = useContext(GlobalPlaygroundContext)

  if (context === defaultContextValue) {
    throw new Error(
      'useGlobalPlaygroundContext must be used within a GlobalPlaygroundProvider',
    )
  }

  return context
}
