import { y } from '@circlefin/form'

import type { GlobalSettings } from '@features/playground.constants/events'

/**
 * Yup schema for validating GlobalSettings.
 */
const globalSettingsSchema = y.object().shape({
  language: y.string().required(),
  client: y.string().required(),
})

/**
 * Retrieves the global settings from local storage.
 * @returns The global settings object. Returns an empty object if no valid settings are found.
 */
export const getGlobalSettings = (): GlobalSettings => {
  let item: string | null = null

  try {
    item = localStorage.getItem('settings.global')
  } catch {
    // We do not need to handle the error, as we will fallback to our default values
  }

  if (item) {
    try {
      const parsedItem = JSON.parse(item) as unknown
      return globalSettingsSchema.validateSync(parsedItem, {
        stripUnknown: true,
      })
    } catch {
      // If JSON parsing or validation fails, do nothing and return the default object
    }
  }

  return {
    client: 'fetch',
    language: 'node',
  }
}

/**
 * Saves the provided global settings to local storage.
 * @param settings - The global settings to save.
 * @throws Will throw an error if the provided settings are invalid.
 */
export const storeGlobalSettings = (settings: GlobalSettings): void => {
  try {
    localStorage.setItem('settings.global', JSON.stringify(settings))
  } catch {
    // We do not need to handle the error, as the settings are still stored in memory
  }
}
