/**
 * Represents the payload of a form change event in the API Playground.
 * This interface defines the structure of data that is emitted when form fields are updated.
 */
export interface FormChangeEventPayload {
  /**
   * An object containing the updated parameters for the API operation.
   * The keys represent parameter categories (e.g., 'query', 'path', 'header'),
   * and the values are objects where keys are parameter names and values are their corresponding values.
   */
  parameters?: Record<string, Record<string, unknown>>

  /**
   * An object representing the updated request body for the API operation.
   * The keys are the top-level fields of the request body, and the values are their corresponding values.
   */
  requestBody?: Record<string, unknown>

  /**
   * The bearer token for authentication, if applicable.
   */
  bearer?: string
}

/**
 * Event name for form changes in the Playground.
 */
export const PLAYGROUND_FORM_CHANGE_EVENT = 'playground:form:changed'

/**
 * Event name for form validation state changes in the Playground.
 */
export const PLAYGROUND_FORM_VALIDATION_STATE_EVENT =
  'playground:form:validate_state'

/**
 * Defines the structure of the event emitter for the API Playground.
 */
export interface PlaygroundEventEmitterType {
  [PLAYGROUND_FORM_CHANGE_EVENT]: FormChangeEventPayload
  [PLAYGROUND_FORM_VALIDATION_STATE_EVENT]: boolean
}

/**
 * GLOBAL Playground.
 */
export interface GlobalPlaygroundWeb3ServicesSettings {
  apiKey?: string
  entitySecret?: string
}

export interface GlobalPlaygroundCircleMintSettings {
  apiKey?: string
}

export type GlobalProjectSettings = Record<
  string,
  Record<string, string | number>
>

/**
 * Represents the global settings for all playground components.
 */
export interface GlobalSettings {
  /**
   * The language setting for the application.
   */
  language: string
  /**
   * The client identifier.
   */
  client: string
}

/**
 * Represents the payload of the global project settings changed event.
 */
export interface GlobalProjectSettingsChangedPayload {
  /**
   * Active global project settings.
   */
  settings: GlobalProjectSettings
  /**
   * Optional identifier for the initiator of the update.
   * Can be used to prevent infinite loops of updating and triggering updates.
   */
  initiator?: string
}

export const GLOBAL_PLAYGROUND_PROJECT_SETTINGS_CHANGED =
  'global.playground:project-settings:changed'

export const GLOBAL_PLAYGROUND_SETTINGS_CHANGED =
  'global.playground:settings:changed'

/**
 * Defines the structure of the event emitter for the API Playground.
 */
export interface GlobalPlaygroundEventEmitterType {
  [GLOBAL_PLAYGROUND_SETTINGS_CHANGED]: GlobalSettings
  [GLOBAL_PLAYGROUND_PROJECT_SETTINGS_CHANGED]: GlobalProjectSettingsChangedPayload
}
