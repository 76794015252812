'use client'

import type { PropsWithChildren } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  GLOBAL_PLAYGROUND_PROJECT_SETTINGS_CHANGED,
  GLOBAL_PLAYGROUND_SETTINGS_CHANGED,
  type GlobalPlaygroundEventEmitterType,
  type GlobalProjectSettings,
  type GlobalSettings,
} from '@features/playground.constants/events'
import { GlobalPlaygroundContext } from '@features/playground.hooks/useGlobalPlaygroundContext'
import Emittery from 'emittery'

import * as settingsModule from './settings/settings'

import type {
  GlobalPlaygroundActions,
  GlobalPlaygroundContextType,
} from '@features/playground.hooks/useGlobalPlaygroundContext'

/**
 * Props for the GlobalPlaygroundProvider component.
 */
export interface GlobalPlaygroundProviderProps extends PropsWithChildren {
  /**
   * Event emitter for testing purposes.
   */
  _emitter?: Emittery<GlobalPlaygroundEventEmitterType>
  /**
   * Initial global settings for testing purposes.
   */
  _initialSettings?: GlobalSettings
  /**
   * Initial project settings for testing purposes.
   */
  _initialProjectSettings?: GlobalProjectSettings
}

/**
 * Default global settings.
 */
const DEFAULT_GLOBAL_SETTINGS: GlobalSettings = {
  client: 'fetch',
  language: 'node',
}

/**
 * Provider component for the Global Playground context.
 * This component initializes the Global Playground state and provides it to all child components.
 * It manages the global settings and project settings, and provides methods
 * to update these values through a context.
 */
export const GlobalPlaygroundProvider: React.FC<
  GlobalPlaygroundProviderProps
> = ({
  children,
  _emitter,
  _initialSettings = DEFAULT_GLOBAL_SETTINGS,
  _initialProjectSettings = {},
}) => {
  // Initialize the event emitter for global playground events
  const [emitter] = useState(
    () => _emitter ?? new Emittery<GlobalPlaygroundEventEmitterType>(),
  )

  // Initialize the mutable references for the global playground state
  const projectSettings = useRef<GlobalProjectSettings>(_initialProjectSettings)
  const globalSettings = useRef<GlobalSettings>(_initialSettings)

  useEffect(() => {
    // Load stored global settings on component mount
    globalSettings.current = settingsModule.getGlobalSettings()
  }, [])

  /**
   * Handles project settings changes by updating the project settings state and emitting a change event.
   */
  const handleProjectSettingsChange = useCallback(
    (
      key: string,
      settings: Record<string, string | number>,
      initiator?: string,
    ) => {
      const nextSettings: GlobalProjectSettings = {
        ...projectSettings.current,
        [key]: {
          ...(projectSettings.current[key] ?? {}),
          ...settings,
        },
      }

      projectSettings.current = nextSettings

      // TODO: Talk to security and figure out if storing testnet api keys would be fine (Mainnet keys would always NOT be stored)

      void emitter.emit(GLOBAL_PLAYGROUND_PROJECT_SETTINGS_CHANGED, {
        settings: nextSettings,
        initiator,
      })
    },
    [emitter],
  )

  /**
   * Handles global settings changes by updating the global settings state, storing the new settings, and emitting a change event.
   */
  const handleGlobalSettingsChange = useCallback(
    (settings: Partial<GlobalSettings>) => {
      const nextSettings: GlobalSettings = {
        ...globalSettings.current,
        ...settings,
      }

      globalSettings.current = nextSettings
      settingsModule.storeGlobalSettings(nextSettings)

      void emitter.emit(GLOBAL_PLAYGROUND_SETTINGS_CHANGED, nextSettings)
    },
    [emitter],
  )

  // Memoize the context value to prevent unnecessary re-renders
  const value = useMemo<GlobalPlaygroundContextType>(
    () => [
      { global: globalSettings, project: projectSettings },
      {
        emitter,
        handleGlobalSettingsChange,
        handleProjectSettingsChange,
      } as GlobalPlaygroundActions,
    ],
    [emitter, handleGlobalSettingsChange, handleProjectSettingsChange],
  )

  return (
    <GlobalPlaygroundContext.Provider value={value}>
      {children}
    </GlobalPlaygroundContext.Provider>
  )
}
